footer {
    background: #3b0251;
    color: white;
    padding: 20px;
    position: relative;
    padding-bottom: 100px;
}

.container-footer {
    display: flex;
    justify-content: space-between;
}

.container-footer .container-logo {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-footer .container-logo .titulo_footer {
    text-align: center;
    color: #fea259;
}

.container-footer .container-logo .descricao_footer {
    text-align: justify;
    font-style: italic;
    color: #fff;
    padding: 0px 20px;
}

.container-map {
    flex: 1;
}

.map {
    width: 100%;
}

.map iframe {
    width: 100%;
}

/* Media query para telas menores */
@media (max-width: 768px) {

    .container-footer {
        flex-direction: column;
    }

    .container-footer .container-logo {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
    }

    .container-map {
        width: 100%;
    }
}

/* cookie */

.cookie-consent {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    /* Cor de fundo preta com transparência */
    color: white;
    padding: 20px 10px;
    text-align: center;
    z-index: 1000;
    /* Garante que o banner fique acima de outros elementos da página */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.cookie-consent p {
    margin: 0 20px 0 0;
    /* Espaçamento à direita do texto */
    flex: 1;
    /* O texto ocupa o espaço disponível */
}

.cookie-consent button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #4CAF50;
    /* Verde */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cookie-consent button:hover {
    background-color: #45a049;
    /* Um tom mais escuro de verde ao passar o mouse */
}