.programa-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.programa-card {
    width: calc(20% - 20px);
    /* 25% da largura do contêiner pai (subtraindo a margem) */
    margin: 10px;
    position: relative;
    padding: 7px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.5s ease-in;
}

.programa-card:hover {
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-in;
}

.programa-card-title {
    margin-top: 5px;
    margin-bottom: 0px;
    font-family: Roboto Condensed, sans-serif;
    font-size: 1.4rem;
    color: #2c013d;
}

.programa-card-horario {
    color: #656565 !important;
    margin: 0px;
}

.programa-card .card-image {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    /* Evita a repetição da imagem */
    background-position: center;
    /* Centraliza a imagem no contêiner */
    border: solid 1px #e9e9e9;

}

/* Media query para telas menores */
@media (max-width: 768px) {

    /* Exibir apenas 1 card por linha em telas menores */
    .programa-card {
        width: 50%;
        /* 100% da largura do contêiner pai (uma por linha) */
        margin: 10px 0;
        /* Espaçamento entre os cards */
        /* Outros estilos para telas menores */
    }
}

#daySelect {
    background: #2c013d;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    height: 40px;
    margin-bottom: 10px;
    padding: 5px;
    width: 100%;
}

#daySelect option {
    padding: 15px;
}