@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wdth,wght,YTLC@0,6..12,75..125,200..1000,440..540;1,6..12,75..125,200..1000,440..540&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

/* Container principal */
.container {
    max-width: 1140px;
    /* Largura máxima do container */
    margin: 0 auto;
    /* Centralize o container horizontalmente */
    padding: 0 15px;
    /* Adicione um espaço interno às laterais do container */
    padding-bottom: 20px;
}

/* Estilos para o cabeçalho e subtítulo */
h2 {
    font-size: 24px;
    margin-top: 20px;
}

p {
    color: #656565 !important;
}

.slideshow {
    position: relative;
    width: 100%;
    /* Largura máxima desejada */
    margin: 0 auto;
    /* Centraliza o slideshow na tela */
}

.slideshow img {
    width: 100%;
    transition: all 0.5s ease-in;
}

.slideshow .control {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1;
    padding: 20px;
    top: 45%;
    cursor: pointer;
}

@media (max-width: 768px) {

    .slideshow {
        height: 350px;
    }

    .slideshow .control {
        top: 40%;
        padding: 10px;
    }

    .slideshow img {
        height: 350px;
    }
}

@media (min-width: 768px) {
    .slideshow {
        height: 550px;
    }

    .slideshow img {
        width: 100%;
    }
}

.slideshow .control-icon-left {
    left: 0;
}

.slideshow .control-icon-right {
    right: 0;
}

.fade {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.fade.visible {
    opacity: 1;
}

.fade.hidden {
    display: none; /* Oculta completamente se necessário */
}

.loading-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px; /* Ajuste para o tamanho do slideshow */
    background: #f0f0f0; /* Cor de fundo do placeholder */
    color: #888;
    font-size: 16px;
    font-weight: bold;
}

/* GERAL */

.titulos-home {
    font-family: "Roboto Condensed", sans-serif;
    margin-top: 0px;
    font-size: 2rem;
    color: #fea259;
}

.call-buttons {
    background-color: #2c013d;
    /* border: 1px solid #fea259; */
    color: #fff;
    padding: 7px 40px;
    border-radius: 5px;
    text-decoration: none;
    text-transform: uppercase;
    font-family: Roboto Condensed, sans-serif;
    font-weight: 300;
}

.section-noticias {
    padding: 20px 0px;
}

.section-programas {
    background-color: #f9f9f9;
    padding: 20px 0px;
}

.section-sobre {
    padding: 20px 0px;
}

.section-sobre p {
    color: #444;
    font-family: Nunito Sans, sans-serif;
    font-size: 1.25rem;
    font-weight: 300;
}

.section-contato {
    padding: 20px 0px;
    padding-bottom: 60px;
    background-color: #f9f9f9;
}

.container-2coluns-home {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.container-redes-home {
    width: 50%;
    padding-right: 5px;
    box-sizing: border-box;
}

.container-redes-home p {
    font-size: 1.2rem;
}

.container-redes-home p span {
    display: flex;
    font-weight: bold;
}

.container-redes {
    display: flex;
    margin-top: 10px;
}

.icone-social-container {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease-in;
}

.icone-social-container:hover {
    background: #333 !important;
    transition: all 0.5s ease-in;
}

.icone-social-container svg {
    color: #fff !important;
}

.facebook-bg {
    background-color: #3b5998;
}

.instagram-bg {
    background-color: #e4405f;
}

.youtube-bg {
    background-color: #cd201f;
}

.whatsapp-bg {
    background-color: #25d366;
}

.linkedin-bg {
    background-color: #0077b5;
}

.googleplus-bg {
    background-color: #dd4b39;
}

.twitter-bg {
    background-color: #55acee;
}

.container-redes-home p a {
    color: #333;
}

.container-contato-home {
    width: 50%;
    padding-left: 5px;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .container-contato-home {
        width: 100%;
        padding-left: 0px;
    }

    .container-redes-home {
        width: 100%;
        padding-right: 0px;
    }
}

/* HEADER */

.container-redes-header {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    align-items: center;
}

.logo-container {
    margin-left: 20px;
}

.logo-container img {
    width: 108px;
}

.container-menu-header {
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.container-redes-header .link-header {
    color: #fff;
    padding: 0px 10px;
    font-size: 0.9rem;
}

.container-redes-header .link-header svg {
    font-size: 0.7rem;
    margin-right: 2px;
}

@media (max-width: 768px) {
    .container-redes-header .link-header {
        display: none !important;
    }

    .logo-container img {
        width: 78px;
    }
}

/* UTILIDADES */

.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.margin-top-20 {
    margin-top: 20px;
}

/* ADSENSE */

.container-publicidade {
    background-color: #f5f5f5;
    border: 1px dashed #e9e9e9;
    display: flex;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
}

.adsbygoogle {
    display: block;
    min-width: 300px;
    /* Defina para o menor tamanho de anúncio que você espera exibir */
    width: 100%;
    max-width: 100%;
    /* ou qualquer largura conforme necessário */
    height: auto;
    /* ajuste conforme necessário */
}